import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import FirstPageSection from "../../components/common/firstPageSection/FirstPageSection";
import MainLayout from "../../components/layout/MainLayout";
import { getDataByApiName } from "../../utlis/getDataByApiName";
import ContactForm from "./ContactForm";
import DomParser from "../../components/common/domParser/DomParser";

function DaringHub({ daringPage }) {
  const firstSectionImage = getDataByApiName(
    daringPage[0],
    "images",
    "daring-hub-img"
  );
  const firstSectionTitle = getDataByApiName(
    daringPage[0],
    "texts",
    "daring-hub-title"
  );
  const firstSectionInfo = getDataByApiName(
    daringPage[0],
    "texts",
    "daring-hub-info"
  );
  const isArabic = useSelector((state) => state.language.isArabic);
  useEffect(() => {
    document.title = "Daring Hub";
  }, []);
  return (
    <>
      <MainLayout addMargin={false}>
        <FirstPageSection
          firstSectionTitle={firstSectionTitle}
          sectionImage={firstSectionImage}
        />
      </MainLayout>
      <MainLayout>
        <DomParser
          htmlResponse={
            isArabic ? firstSectionInfo.content_ar : firstSectionInfo.content
          }
        />
      </MainLayout>
      <MainLayout>
        <ContactForm />
      </MainLayout>
    </>
  );
}

export default DaringHub;
