import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DomParser from "../../components/common/domParser/DomParser";
import { Col, Row } from "react-bootstrap";
import ServerImage from "../../components/common/server-images/ServerImage";
import NotFound from "../NotFound/NotFound";
import MainLayout from "../../components/layout/MainLayout";

function SingleCampaign({ campaignsList }) {
  const { id } = useParams();
  const isArabic = useSelector((state) => state.language.isArabic);

  const c = campaignsList.find((c) => c.id === parseInt(id));
  if (!c) return <NotFound />;
  const i = campaignsList.indexOf(c);
  return (
    <>
      <MainLayout>
        <Row>
          <Col className="campaigns-title" xs={12} sm={12}>
            <Row className="campaign-num single">
              {i < 10 ? "0" + (i + 1) : i + 1}
            </Row>
            <DomParser htmlResponse={!isArabic ? c.title : c.title_ar} />
          </Col>
        </Row>
      </MainLayout>
      <MainLayout>
        <Row className="mt-3">
          <Col xs={12} sm={12} className="campaigns-text-img single">
            {c.image && <ServerImage src={c.image} />}
            <DomParser htmlResponse={!isArabic ? c.content : c.content_ar} />
          </Col>
        </Row>
      </MainLayout>
    </>
  );
}

export default SingleCampaign;
