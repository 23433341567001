import { routes } from "../../routes/Routes";

export const getPagesLinks = (programs, projects, t) => {
  const programsDropDownMenu = programs.map((p) => {
    return {
      id: p.id,
      title: p.title,
      title_ar: p.title_ar,
      path: routes.programRoute + `/${p.id}`,
    };
  });

  const projectsDropDownMenu =
    projects &&
    projects.map((p) => {
      return {
        id: p.id,
        title: p.name,
        title_ar: p.name_ar,
        path: routes.projectRoute + `/${p.id}`,
      };
    });
  return [
    {
      label: t("about-us"),
      path: routes.aboutUsRoute,
      path2: routes.aboutUsRoute2,
    },
    { label: t("campaings"), path: routes.campaignsRoute },
    { label: t("programs"), dropDownMenu: programsDropDownMenu },
    { label: t("projects"), dropDownMenu: projectsDropDownMenu },
    { label: t("blog"), href: routes.blogRoute },
    { label: t("m-space"), path: routes.makerspaceRoute },
    {
      id: "multiple-navs",
      nav1: {
        label: t("join-us"),
        href: routes.iDareEventWebsite,
      },
      nav2: {
        label: t("daring-hub"),
        path: routes.daringHubRoute,
      },
    },
  ];
};
