import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../components/layout/MainLayout";
import FirstPageSection from "../../components/common/firstPageSection/FirstPageSection";
import NotFound from "../NotFound/NotFound";
import DomParser from "../../components/common/domParser/DomParser";
import { useSelector } from "react-redux";
import { Col } from "react-bootstrap";

function Program({ programs }) {
  const { id } = useParams();
  const program = programs.find((p) => p.id === parseInt(id));
  const isArabic = useSelector((state) => state.language.isArabic);
  useEffect(() => {
    if (program) {
      document.title = program.title;
    }
  }, [program]);
  if (!program) return <NotFound />;

  const sectionImage = {
    active: true,
    image: program.image,
    alt: "prog-media",
    alt_ar: "prog-media",
  };

  const sectionInfo = {
    active: true,
    content: program.text,
    content_ar: program.text_ar,
  };
  const sectionContent = {
    content: program.content,
    content_ar: program.content_ar,
  };
  return (
    <>
      <MainLayout addMargin={false} className="program-page">
        {program.image && (
          <FirstPageSection
            sectionImage={sectionImage}
            firstSectionInfo={sectionInfo}
          />
        )}
      </MainLayout>
      <Col xs={12} sm={12} className="mt-5">
        <DomParser
          htmlResponse={
            isArabic ? sectionContent.content_ar : sectionContent.content
          }
        />
      </Col>
    </>
  );
}

export default Program;
