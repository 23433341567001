import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/dist";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { sortData } from "../../utlis/sort";
import NotFound from "../NotFound/NotFound";
import DomParser from "../../components/common/domParser/DomParser";
import AllEvents from "./AllEvents";
function ProjectPage({ projects }) {
  const { t } = useTranslation();
  const { pid } = useParams();
  const { isArabic } = useSelector((state) => state.language);
  const project = projects.find((p) => p.id === parseInt(pid));
  useEffect(() => {
    if (project) document.title = project.name;
  }, [project]);
  if (!project) return <NotFound />;

  return (
    <>
      <Row className="justify-content-between project-page">
        <Col xs={12} sm={12}>
          <h5>{isArabic ? project.name_ar : project.name}</h5>
        </Col>
      </Row>
      {project.long_description_ar || project.long_description ? (
        <Row className="justify-content-center my-2">
          <Col xs={12} sm={12}>
            <DomParser
              htmlResponse={
                isArabic
                  ? project.long_description_ar
                  : project.long_description
              }
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <AllEvents
        title={t("events")}
        events={sortData(project.Events, "start_time", "asc")}
      />
    </>
  );
}

export default ProjectPage;
