import React from "react";

import BurgerMenu from "./BurgerMenu";
import LanguageSwitcher from "./LanguageSwitcher";

function RightHeaderSide({ programs, projects }) {
  return (
    <div className="header_right-side">
      <LanguageSwitcher />
      <BurgerMenu programs={programs} projects={projects} />
    </div>
  );
}

export default RightHeaderSide;
