import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import firstArrow from "../../assets/images/firstArrow.png";
import secondArrow from "../../assets/images/secondArrow.png";
import { useSelector } from "react-redux";
import ServerImage from "../common/server-images/ServerImage";
import { getCustomCards } from "../../utlis/getCustomCards";
import DomParser from "../common/domParser/DomParser";

function AgencySections({ homePage }) {
  const isArabic = useSelector((state) => state.language.isArabic);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(getCustomCards(homePage, "youthAgencyCards"));
  }, [homePage]);
  const arrows = [firstArrow, secondArrow];
  return (
    <Row className="justify-content-center mt-3 pt-4">
      {data.map((d, i) => {
        return (
          <Col
            xs={11}
            sm={11}
            md={9}
            key={d.title}
            className="my-4 agency-title-desc-container"
          >
            {d.image ? (
              <Row className="justify-content-center my-2">
                <ServerImage src={d.image} alt="icon" className="numbers" />
              </Row>
            ) : (
              <></>
            )}
            <Row className="justify-content-center">
              <p className="agency-title">{isArabic ? d.title_ar : d.title}</p>
            </Row>
            <Row className="justify-content-center">
              <p className="agency-desc">
                <DomParser htmlResponse={!isArabic ? d.text : d.text_ar} />
              </p>
            </Row>
            {i + 1 < data.length ? (
              <Image src={arrows[i]} alt="icon" className={`arrow-${i + 1}`} />
            ) : (
              <></>
            )}
          </Col>
        );
      })}
    </Row>
  );
}

export default AgencySections;
