import React from "react";
import PagesRoutes from "./pagesRoutes";

function MobileHeader({ openMenu, setOpenMenu, programs, projects }) {
  return (
    <div className={`burger-menu_list${openMenu ? " active" : " inactive"}`}>
      <PagesRoutes
        setOpenMenu={setOpenMenu}
        programs={programs}
        projects={projects}
      />
    </div>
  );
}

export default MobileHeader;
