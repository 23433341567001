import { Toast } from "../components/common/toast/Toast";
import { http } from "./httpService";
const apiEndPoint = process.env.REACT_APP_BASE_EVENTS_URL;
export const getProjects = (t) => {
  try {
    return http.get(apiEndPoint + "/projects");
  } catch (error) {
    return Toast("error", t("error-occured"));
  }
};
