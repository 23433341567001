import _ from "lodash";

export const setAppFont = (settings, stylePath, linkPath) => {
  // Find the link and style code from the settings using lodash
  const linkCode = _.get(_.find(settings, { name: linkPath }), "value", null);
  const styleCode = _.get(_.find(settings, { name: stylePath }), "value", null);

  // If either linkCode or styleCode is not found, exit early
  if (!linkCode || !styleCode) return;

  // Create a temporary container for the link code
  const linkContainer = document.createElement("div");
  linkContainer.innerHTML = linkCode;

  // Check if the link already exists by href attribute using lodash
  const linkExists = _.some(
    document.head.querySelectorAll("link"),
    (link) => _.get(link, "href") === linkContainer.querySelector("link").href
  );

  // Append link if it doesn't exist
  if (!linkExists) {
    document.head.appendChild(linkContainer.querySelector("link"));
  }

  // Inject the style code
  generateAndInjectCss(styleCode, "my-unique-class", 400);
};

export const generateAndInjectCss = (cssString, uniquifier, weight) => {
  // Replace <uniquifier> and <weight> using lodash replace
  const updatedCssString = _.replace(cssString, /<uniquifier>/g, uniquifier);
  const finalCssString = _.replace(updatedCssString, /<weight>/g, weight);

  // Extract CSS properties from the body of the string
  const object = {};
  const cssBody = _.get(finalCssString.match(/{([^}]+)}/), 1, "");

  // Use lodash to split, map and reduce the CSS properties into an object
  _.forEach(cssBody.split(";").filter(Boolean), (property) => {
    const [key, value] = _.map(property.split(":"), (item) => _.trim(item));
    object[key] = value;
  });

  // Check if the style element already exists using lodash's find
  let styleElement = _.find(document.head.querySelectorAll("style"), {
    id: "dynamic-css-style",
  });

  if (!styleElement) {
    // Create new style element if not found
    styleElement = document.createElement("style");
    styleElement.id = "dynamic-css-style";
    document.head.appendChild(styleElement);
  }

  // Build CSS rule for body#body using lodash
  const cssRules = _.reduce(
    object,
    (result, value, key) => result + `${key}: ${value} !important; `,
    ""
  );

  // Inject CSS into the style element
  styleElement.innerHTML = `body#body * { ${cssRules} }`;

  return object; // Return the object if needed
};
