import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import Campaigns from "../pages/campaings/Campaigns";
import { routes } from "./Routes";
import SingleCampaign from "../pages/campaings/SingleCampaign";
import Program from "../pages/program/Program";
import NotFound from "../pages/NotFound/NotFound";
import DaringHub from "../pages/daring-hub/DaringHub";
import MakerSpace from "../pages/makerspace/MakerSpace";
import Project from "../pages/projects/Project";

function MainRoutes({
  homePage,
  campaignsPage,
  daringPage,
  campaignsList,
  programs,
  logos,
  projects,
  makerspacePage,
}) {
  return (
    <Routes>
      <Route
        path={routes.aboutUsRoute}
        element={
          homePage.length &&
          homePage[0].active && <Home homePage={homePage} logos={logos} />
        }
      />
      <Route
        path={routes.singleProjectRoute}
        element={<Project projects={projects} />}
      />
      <Route
        path={routes.aboutUsRoute2}
        element={
          homePage.length &&
          homePage[0].active && <Home homePage={homePage} logos={logos} />
        }
      />
      <Route
        path={routes.campaignsRoute}
        element={
          campaignsPage.length &&
          campaignsPage[0].active && (
            <Campaigns
              campaignsPage={campaignsPage}
              campaignsList={campaignsList}
            />
          )
        }
      />
      <Route
        path={routes.makerspaceRoute}
        element={
          makerspacePage.length &&
          makerspacePage[0].active && (
            <MakerSpace makerspacePage={makerspacePage[0]} />
          )
        }
      />
      <Route
        path={routes.singleCampaignRoute}
        element={<SingleCampaign campaignsList={campaignsList} />}
      />
      <Route
        path={routes.singleProgramRoute}
        element={<Program programs={programs} />}
      />
      <Route
        path={routes.daringHubRoute}
        element={
          daringPage.length &&
          daringPage[0].active && <DaringHub daringPage={daringPage} />
        }
      />
      <Route path={routes.notFoundRoute} element={<NotFound />} />
    </Routes>
  );
}

export default MainRoutes;
