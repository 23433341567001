import React, { useState } from "react";
import { Nav, NavDropdown, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DrawnBorder from "../../border/DrawnBorder";
import DropDownMenu from "./DropDownMenu";
import { routes } from "../../../routes/Routes";
import { useSelector } from "react-redux";

function NavItems({ links, isFooter = false, setOpenMenu }) {
  const navigate = useNavigate();
  const isArabic = useSelector((state) => state.language.isArabic);
  const handleClickNavLink = (n) => {
    if (n.href2) {
      navigate(routes.aboutUsRoute + n.href2, { behavior: "smooth" });
    }
    if (n.href) {
      return window.open(n.href, "_blank");
    }
    if (n.path) {
      navigate(n.path);
    }

    if (!n.href2)
      window.scrollTo({ top: 0, behavior: isFooter ? "smooth" : "auto" });
    if (setOpenMenu) setOpenMenu(false);
  };

  const [activeLink, setActiveLink] = useState(-1);

  return links.map((n, i) => {
    return (
      <Nav
        style={{ position: "relative" }}
        onMouseEnter={() => setActiveLink(i)}
        onMouseLeave={() => {
          setActiveLink(-1);
        }}
        key={n.path || n.href || n.label || n.id}
      >
        {activeLink === i && !isFooter && !n.nav1 && <DrawnBorder />}
        {n.dropDownMenu ? (
          <>
            <NavDropdown
              title={n.label}
              id="basic-nav-dropdown"
              className={isArabic ? "ar" : ""}
            >
              <DropDownMenu
                links={n.dropDownMenu}
                handleClickNavLink={handleClickNavLink}
              />
            </NavDropdown>
          </>
        ) : !n.nav1 ? (
          <NavLink
            onClick={() => !n.href && handleClickNavLink(n)}
            href={n.href && n.href}
            target="_blank"
            active={
              (window.location.pathname === n.path ||
                window.location.pathname === n.path2) &&
              !isFooter
            }
          >
            {n.label}
          </NavLink>
        ) : (
          <NavLink style={{ paddingLeft: "0", paddingRight: "0" }}>
            <span
              onClick={() => handleClickNavLink(n.nav1)}
              className="apply"
              style={{
                fontWeight:
                  window.location.pathname === n.nav1.path ? "bold" : "",
              }}
              onMouseEnter={() => setActiveLink("apply")}
              onMouseLeave={() => setActiveLink(-1)}
            >
              {activeLink === "apply" && <DrawnBorder />}
              <span>{n.nav1.label}</span>
            </span>
            <span>|</span>
            <span
              onClick={() => handleClickNavLink(n.nav2)}
              className="daring-hub"
              style={{
                fontWeight:
                  window.location.pathname === n.nav2.path ? "bold" : "",
              }}
              onMouseEnter={() => setActiveLink("daring-hub")}
              onMouseLeave={() => setActiveLink(-1)}
            >
              {activeLink === "daring-hub" && <DrawnBorder />}
              <span>{n.nav2.label}</span>
            </span>
          </NavLink>
        )}
      </Nav>
    );
  });
}

export default NavItems;
